import * as React from 'react'
import { unwindEdges } from '@good-idea/unwind-edges'

import { useModal } from '@/providers/ModalProvider'
import { Modal } from '@/components/Modal'
import { Heading } from '@/components/Text'
import { useShopify } from '@/providers/ShopifyProvider'
import { LineItemSummary } from './LineItemSummary'
import { NoLineItems } from './NoLineItems'
import { PaymentSummary } from './PaymentSummary'

export const CartModal: React.FC = () => {
  const { closeModal } = useModal()

  const { checkout } = useShopify()
  const [lineItems] = unwindEdges(checkout?.lineItems)
  return (
    <Modal>
      <Heading
        level="h2"
        fontSize={4}
        fontWeight={1}
        textTransform="uppercase"
        textAlign="center"
      >
        Order Summary
      </Heading>
      {lineItems.length && checkout ? (
        <>
          <LineItemSummary lineItems={lineItems} />
          <PaymentSummary checkout={checkout} />
        </>
      ) : (
        <NoLineItems closeCartModal={closeModal} />
      )}
    </Modal>
  )
}

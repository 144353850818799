import styled from '@xstyled/styled-components'

/**
 * Line Items
 */
export const LineItemSummaryWrapper = styled.ul`
  margin-top: 8;
  @media screen and (max-width: 768px) {
    margin-top: 2;
    padding-left: 0;
    margin-top: auto;
  }
`

const IMAGE_SIZE = '220px'
const IMAGE_SIZE_MOBILE = '120px'

export const CheckoutLineItemWrapper = styled.li`
  & + & {
    margin-top: 8;
  }
  display: grid;
  grid-template-columns: ${IMAGE_SIZE} 1fr;
  grid-column-gap: 55px;
  button {
    font-family: var(--sans);
    font-weight: 500;
    text-transform: uppercase;
    font-size: 6;
    padding-top: 2;
    color: black;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: ${IMAGE_SIZE_MOBILE} 1fr;
    grid-column-gap: 20px;
    & + & {
      margin-top: 3;
    }
    p {
      font-size: 6;
    }
    p.title {
      font-size: 5;
      margin-top: 1;
    }
    button {
      font-size: 7;
      padding-top: 2;
    }
  }
`

export const CheckoutLineItemImageWrapper = styled.div`
  border: 1px solid black;
  background-color: lightGray;
  padding: 2;
  width: ${IMAGE_SIZE};
  height: ${IMAGE_SIZE};
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    width: ${IMAGE_SIZE_MOBILE};
    height: ${IMAGE_SIZE_MOBILE};
  }
`

export const CheckoutLineItemDetails = styled.div`
  /* Fudge the top margin to get the top of the text to align better */
  margin-top: -8px;

  > * {
    margin: 0;
  }
  & > *:nth-child(n + 2) {
    margin-top: 3;
  }
  @media screen and (max-width: 768px) {
    & > *:nth-child(n + 2) {
      margin-top: 1;
    }
  }
`

/**
 * Payment Summary
 */

export const PaymentSummaryWrapper = styled.div`
  max-width: 450px;
  margin-top: 8;
  margin-left: auto;
  width: 100%;
  @media screen and (max-width: 768px) {
    margin-top: auto;
    max-width: 100%;
    width: 100%;
    padding-bottom: 2;
  }
`

export const SummaryInfoLine = styled.div`
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  font-size: 5;
  font-family: serif;
  margin-bottom: 3;

  & + & {
    margin-top: 4;
  }

  &:last-child {
    margin-bottom: 2;
  }
  @media screen and (max-width: 768px) {
    font-size: 6;
    margin-bottom: 2;
    & + & {
      margin-top: 2;
    }
    span.total {
      font-size: 5;
      line-height: 1.5;
    }
  }
`

import React, { useEffect } from 'react'

import { useModal } from '@/providers/ModalProvider'
import { useLockScroll } from '@/hooks/useLockScroll'
import {
  ModalBackground,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalWrapper,
} from './styles'

interface ModalProps extends ModalContentProps {
  children: React.ReactNode
}

export const Modal: React.FC<ModalProps> = ({ children, backgroundColor }) => {
  const { closeModal } = useModal()
  const { lockScroll, unlockScroll } = useLockScroll()
  useEffect(() => {
    lockScroll()
    return () => unlockScroll()
  }, [])
  return (
    <ModalWrapper>
      <ModalBackground onClick={closeModal} />
      <ModalContent backgroundColor={backgroundColor}>
        <ModalCloseButton onClick={closeModal}>X</ModalCloseButton>
        {children}
      </ModalContent>
    </ModalWrapper>
  )
}

import * as React from 'react'

import { useSiteSettings } from '@/providers/SiteSettingsProvider'
import { definitely } from '@/utils'
import Image from 'next/image'
import { Span } from '../Text'
import {
  FooterWrapper,
  LinksWrapper,
  SubscribeWrapper,
  MobileNav,
  NavButton,
} from './styles'
import { InputWithButton } from '../Inputs'
import { Sentry } from '@/lib/sentry'
import Link from 'next/link'
import { useModal } from '@/providers/ModalProvider'

export const Footer: React.FC = () => {
  const { siteSettings } = useSiteSettings()
  const { openContactModal, openCartModal } = useModal()
  const handleMailerSubscribe = async (email: string) => {
    try {
      await fetch('/api/mailchimpSubscribe', {
        method: 'POST',
        body: JSON.stringify({ email }),
      }).then((r) => r.json())

      return { success: true, message: 'Thank you!' }
    } catch (e) {
      Sentry.captureException(e, 'mailchimp_error_client')
      return { success: false, message: 'Sorry, something went wrong' }
    }
  }
  return (
    <FooterWrapper>
      <SubscribeWrapper>
        <InputWithButton
          name="mailerSubscribe"
          type="email"
          placeholder="ENTER EMAIL FOR UPDATES"
          onSubmit={handleMailerSubscribe}
        />
      </SubscribeWrapper>

      <LinksWrapper>
        {definitely(siteSettings.footer?.footerLinks).map((link, i) => (
          <a key={i} href={link.url}>
            <Span fontFamily="serif">{link.label}</Span>
          </a>
        ))}
      </LinksWrapper>
    </FooterWrapper>
  )
}

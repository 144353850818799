import * as React from 'react'

const { useState, useCallback } = React

export const useLockScroll = (initialState?: boolean) => {
  const [locked, setLocked] = useState(initialState || false)

  const scrollingElement =
    typeof document !== 'undefined'
      ? document?.getElementsByTagName('body')[0]
      : null

  const unlockScroll = useCallback(() => {
    if (scrollingElement) scrollingElement.style.overflow = 'scroll'
    setLocked(false)
  }, [])
  const lockScroll = useCallback(() => {
    if (scrollingElement) scrollingElement.style.overflow = 'hidden'
    setLocked(true)
  }, [])

  return {
    locked,
    unlockScroll,
    lockScroll,
  }
}

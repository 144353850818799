import { DefaultTheme } from '@xstyled/styled-components'

const sans = '"Helvetica Neue", helvetica, sans-serif'

// TODO set up proper font stack
const serif = '"Baskerville", Georgia, sans-serif'

export type Color = keyof DefaultTheme['colors']

export const defaultTheme: DefaultTheme = {
  /*   Spacing - applies to:
   *   margin, margin-top, margin-right, margin-bottom, margin-left, padding,
   *   padding-top, padding-right, padding-bottom, padding-left, grid-gap,
   *   grid-column-gap, grid-row-gap
   */
  space: [0, 3, 6, 12, 18, 24, 38, 44, 48, 64, 72, 120, 148],

  fontSizes: [
    0,
    96, // h1
    48, // h2
    36, // h3
    32, // h4
    20, // h5
    14, // readable text: p, h6
    11, // small text:  captions
  ],

  // tabletFontSizes: [0, 73, 41, 25, 17, 13, 11, 11],
  // mobileFontSizes: [0, 42, 22, 17, 17, 13, 11, 11],
  fontWeights: [0, 100, 200, 300, 400, 700],

  fonts: {
    serif,
    sans,
    display: serif,
    body: serif,
  },

  zIndices: {
    main: 0,
    nav: 100,
    cart: 200,
    dialog: 300,
    alert: 400,
  },

  transition: {
    fast: '150ms',
    slow: '250ms',
  },

  colors: {
    babyPink: '#FC8AB3',
    turtleGreen: '#C8DAA0',
    neonGreen: '#03FF68',
    mustard: '#B78909',
    white: '#fff',
    lightGray: '#D9D9D9',
    mediumGray: '#CBCBCB',
    black: '#000',
  },

  mediaQueries: {
    tablet: '@media screen and (min-width: 1001px)',
    desktop: '@media screen and (min-width: 1200px)',
  },
}

import * as React from 'react'
import { ShopifyStorefrontCheckout } from '@/types/generated-shopify'
import { LinkButton } from '@/components/Button'
import { Text } from '@/components/Text'
import { PaymentSummaryWrapper, SummaryInfoLine } from './styles'
import { formatPrice } from '@/utils/formatting'

interface PaymentSummaryProps {
  checkout: ShopifyStorefrontCheckout
}

export const PaymentSummary: React.FC<PaymentSummaryProps> = ({ checkout }) => {
  const href = checkout.webUrl
  return (
    <PaymentSummaryWrapper>
      <SummaryInfoLine>
        <span>Subtotal</span>
        <span>{formatPrice(checkout.subtotalPriceV2)}</span>
      </SummaryInfoLine>

      <SummaryInfoLine>
        <span>Sales Tax</span>
        <span>{formatPrice(checkout.totalTaxV2)}</span>
      </SummaryInfoLine>

      <SummaryInfoLine>
        <span>Shipping</span>
        <span>TBD</span>
      </SummaryInfoLine>

      <SummaryInfoLine>
        <Text
          className="total"
          fontFamily="sans"
          as="span"
          fontWeight={5}
          fontSize={4}
        >
          Total
        </Text>
        <Text
          className="total"
          fontFamily="sans"
          as="span"
          fontWeight={5}
          fontSize={4}
        >
          {formatPrice(checkout.paymentDueV2)}
        </Text>
      </SummaryInfoLine>

      <LinkButton className="checkout-button" href={href}>
        Checkout
      </LinkButton>
    </PaymentSummaryWrapper>
  )
}

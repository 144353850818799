import { gql } from 'graphql-request'

export const moneyV2Fragment = gql`
  fragment MoneyV2Fragment on MoneyV2 {
    amount
    currencyCode
  }
`

export const imageFragment = gql`
  fragment ImageFragment on Image {
    __typename
    id
    altText
    url
    w100: transformedSrc(maxWidth: 100, crop: CENTER)
    w300: transformedSrc(maxWidth: 300, crop: CENTER)
    w800: transformedSrc(maxWidth: 800, crop: CENTER)
    w1200: transformedSrc(maxWidth: 1200, crop: CENTER)
    w1600: transformedSrc(maxWidth: 1600, crop: CENTER)
  }
`

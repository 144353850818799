import { useReducer } from 'react'

export type FormState = 'idle' | 'pending' | 'success' | 'error'

interface State {
  formState: FormState
  errorMessage: string | null
}

const initialState: State = {
  formState: 'idle',
  errorMessage: null,
}

type ResetAction = { type: 'reset' }
type SetPendingAction = { type: 'setPending' }
type SuccessAction = { type: 'success' }
type RejectedAction = { type: 'rejected'; errorMessage: string }

type Action = ResetAction | SetPendingAction | SuccessAction | RejectedAction

const reducer = (prevState: State, action: Action): State => {
  switch (action.type) {
    case 'reset': {
      return initialState
    }
    case 'setPending': {
      return { ...prevState, formState: 'pending', errorMessage: null }
    }
    case 'success': {
      return { ...prevState, formState: 'success', errorMessage: null }
    }
    case 'rejected': {
      return {
        ...prevState,
        formState: 'error',
        errorMessage: action.errorMessage,
      }
    }
    default:
      return prevState
  }
}

export const useFormState = () => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const setPending = () => dispatch({ type: 'setPending' })
  const handleError = (errorMessage: string) =>
    dispatch({ type: 'rejected', errorMessage })
  const handleSuccess = () => dispatch({ type: 'success' })
  const reset = () => dispatch({ type: 'reset' })

  return {
    ...state,
    setPending,
    handleError,
    handleSuccess,
    reset,
  }
}

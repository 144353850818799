import * as React from 'react'
import { ShopifyStorefrontImage } from '@/types'

interface ShopifyImageProps {
  image: ShopifyStorefrontImage
}

// TODO: use srcSet, altText, sizes, other good things
export const ShopifyImage: React.FC<ShopifyImageProps> = ({ image }) => {
  return <img src={image.url} />
}

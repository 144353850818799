import * as React from 'react'
import styled from '@xstyled/styled-components'
import { FormField, FormFieldProps } from './FormField'

/**
 * Styles
 */

const SelectElement = styled.select`
  font-family: serif;
  font-size: 3;
  border: 1px solid;
  padding: 1 2 0;
`

/**
 * Main component
 */
interface Option {
  label: string
  value: string
}

interface SelectProps extends FormFieldProps {
  options: Option[]
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

export const Select: React.FC<SelectProps> = ({
  id,
  name,
  label,
  value,
  onChange,
  options,
  required,
  validation,
}) => {
  return (
    <FormField id={id} value={value} validation={validation} label={label}>
      {({ onFocus, onBlur }) => (
        <SelectElement
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          required={required}
        >
          {options.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </SelectElement>
      )}
    </FormField>
  )
}

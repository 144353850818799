import * as React from 'react'
import { SiteSettings } from '@/types/sanity'

interface SiteSettingsContextValue {
  siteSettings: SiteSettings
}

const SiteSettingsContext = React.createContext<
  SiteSettingsContextValue | undefined
>(undefined)

export const SiteSettingsConsumer = SiteSettingsContext.Consumer

export const useSiteSettings = () => {
  const ctx = React.useContext(SiteSettingsContext)
  if (!ctx)
    throw new Error(
      'useSiteSettingsContext must be used within a SiteSettingsProvider',
    )
  return ctx
}

interface SiteSettingsProps {
  siteSettings: SiteSettings
  children: React.ReactNode
}

export const SiteSettingsProvider = ({
  children,
  siteSettings,
}: SiteSettingsProps) => {
  const value = {
    siteSettings,
  }

  return (
    <SiteSettingsContext.Provider value={value}>
      {children}
    </SiteSettingsContext.Provider>
  )
}

import styled, { css } from '@xstyled/styled-components'

interface InputWithButtonProps {
  disabled?: boolean
}

const INPUT_HEIGHT = '42px'

export const InputWithButtonWrapper = styled.form<InputWithButtonProps>`
  ${({ disabled }) => css`
    position: relative;
    border: 1px solid black;
    background-color: lightGray;
    display: flex;
    width: 100%;
    height: ${INPUT_HEIGHT};
    font-size: 4;
    pointer-events: ${disabled ? 'none' : 'inherit'};
    pointer-events: ${disabled ? 'none' : 'inherit'};
  `}
`

export const Input = styled.input`
  padding: 1 2 0;
  background-color: lightGray;
  width: 100%;
  padding-right: ${INPUT_HEIGHT};
  font-weight: 4;
`

export const InputButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  border-left: 1px solid black;
  width: ${INPUT_HEIGHT};
  height: 100%;
  text-align: center;
  font-weight: 5;
`

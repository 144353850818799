import React, { useState } from 'react'

interface ModalContextValue {
  currentModal: Modal | null
  openCartModal: () => void
  openContactModal: () => void
  closeModal: () => void
}

const ModalContext = React.createContext<ModalContextValue | undefined>(
  undefined,
)

export const ModalConsumer = ModalContext.Consumer

export const useModal = () => {
  const ctx = React.useContext(ModalContext)
  if (!ctx)
    throw new Error('useModalContext must be used within a ModalProvider')
  return ctx
}

interface ModalProps {
  children: React.ReactNode
}

type Modal = 'cart' | 'contact'

export const ModalProvider = ({ children }: ModalProps) => {
  const [currentModal, setCurrentModal] = useState<Modal | null>(null)

  const closeModal = () => setCurrentModal(null)

  const openCartModal = () => setCurrentModal('cart')
  const openContactModal = () => setCurrentModal('contact')

  const value = {
    currentModal,
    openCartModal,
    openContactModal,
    closeModal,
  }

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
}

import * as React from 'react'
import styled from '@xstyled/styled-components'

import { FormField, FormFieldProps } from './FormField'

/**
 * Styles
 */
const TextAreaElement = styled.textarea`
  width: 100%;
  height: 100%;
  min-height: 400px;
  border: 1px solid;
  padding: 1 2 0;
  font-size: 3;
`
/**
 * Main Component
 */
interface TextAreaProps extends FormFieldProps {
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
}

export const TextArea: React.FC<TextAreaProps> = ({
  label,
  value,
  id,
  name,
  onChange,
  validation,
  required,
}) => {
  return (
    <FormField label={label} id={id} value={value} validation={validation}>
      {({ onFocus, onBlur }) => (
        <TextAreaElement
          name={name}
          id={id}
          onChange={onChange}
          required={required}
          value={value}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      )}
    </FormField>
  )
}

import styled, { css } from '@xstyled/styled-components'

const sharedStyles = css`
  border: 1px solid black;
  font-size: 4;
  font-family: serif;
  padding: 2 4;
  display: block;
  text-align: center;
  color: black;
  @media screen and (max-width: 768px) {
    font-size: 5;
    text-transform: uppercase;
    padding: 3 4;
  }
`

/**
 * Buttons
 */
interface ButtonProps {
  disabled?: boolean
}

export const Button = styled.buttonBox<ButtonProps>`
  ${({ disabled }) => css`
    ${sharedStyles};
    background-color: neonGreen;
    width: 100%;
    opacity: ${disabled ? 0.8 : 1};
    pointer-events: ${disabled ? 'none' : 'inherit'};
  `}
`

export const LinkButton = styled.aBox`
  ${sharedStyles};
  background-color: neonGreen;
`

import React, { useState } from 'react'
import styled from '@xstyled/styled-components'

import { Label, Span } from '../Text'

const FormFieldWrapper = styled.div`
  & + & {
    margin-top: 4;
  }
`

/**
 * Shared by all form fields
 */
export interface FormFieldProps {
  id: string
  name: string
  label?: string
  /* A validation function that returns undefined
   * if the value is valid, and an error message
   * if it is not. */
  validation?: (value: any) => void | string
  value: any
  required?: boolean
}

interface RenderProps {
  onFocus: () => void
  onBlur: () => void
}

interface FormFieldComponentProps extends Omit<FormFieldProps, 'name'> {
  children: (renderProps: RenderProps) => React.ReactNode
}

export const FormField: React.FC<FormFieldComponentProps> = ({
  label,
  id,
  children,
  validation,
  value,
}) => {
  const errorMessage = validation ? validation(value) : null
  const [userHasBlurredOnce, setUserHasBlurredOnce] = useState(false)

  const onFocus = () => {
    /* To do - not yet implemented */
  }
  const onBlur = () => setUserHasBlurredOnce(true)

  return (
    <FormFieldWrapper>
      {label ? <Label htmlFor={id}>{label}</Label> : null}
      {children({ onFocus, onBlur })}
      {userHasBlurredOnce && errorMessage ? (
        <Span color="red" fontFamily="sans" fontSize={5}>
          {errorMessage}
        </Span>
      ) : null}
    </FormFieldWrapper>
  )
}

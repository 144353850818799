import { ContactMessageType } from '@/types'

export const getContactMessageLabel = (
  messageType: ContactMessageType,
): string => {
  switch (messageType) {
    case ContactMessageType.SHOP:
      return 'Shop Questions'
    // case ContactMessageType.GENERAL:
    //   return 'General'
    case ContactMessageType.SHIPPING_RETURNS:
      return 'Shipping & Returns'
    // case ContactMessageType.CAREERS:
    //   return 'Careers'
    default:
      // @ts-expect-error
      throw new Error(`"${messageType.toString()}" is not a valid message type`)
  }
}

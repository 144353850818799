import * as z from 'zod'
import getConfig from 'next/config'

/* Frontend config only. See ./serverConfig for server */
const { publicRuntimeConfig } = getConfig()

const {
  SHOPIFY_ACCESS_TOKEN,
  SHOPIFY_SHOP_NAME,
  SANITY_PROJECT_ID,
  SANITY_DATASET,
  MAILCHIMP_API_KEY,
  MAILCHIMP_LIST_ID,
} = publicRuntimeConfig

const values = {
  sanity: {
    projectId: SANITY_PROJECT_ID,
    dataset: SANITY_DATASET,
  },
  shopify: {
    shopName: SHOPIFY_SHOP_NAME,
    accessToken: SHOPIFY_ACCESS_TOKEN,
  },
  mailchimp: {
    apiKey: MAILCHIMP_API_KEY,
    listId: MAILCHIMP_LIST_ID,
  },
}

const configSchema = z.object({
  sanity: z.object({
    projectId: z.string(),
    dataset: z.string(),
  }),
  shopify: z.object({
    shopName: z.string(),
    accessToken: z.string(),
  }),
  mailchimp: z.object({
    // Make sure mailchimp keys do not leak to the
    // frontend
    apiKey: z.undefined(),
    listId: z.undefined(),
  }),
})

export const config = configSchema.parse(values)

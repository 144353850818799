import { gql } from 'graphql-request'
import { imageFragment, moneyV2Fragment } from './shared'

export const productVariantFragment = gql`
  fragment ProductVariantFragment on ProductVariant {
    __typename
    id
    availableForSale
    currentlyNotInStock
    selectedOptions {
      name
      value
    }
    image {
      ...ImageFragment
    }
    priceV2 {
      ...MoneyV2Fragment
    }
    title
  }
`

export const productQuery = gql`
  query ProductQuery($handle: String!) {
    productByHandle(handle: $handle) {
      id
      __typename
      availableForSale
      descriptionHtml
      handle
      title
      images(first: 99) {
        edges {
          cursor
          node {
            ...ImageFragment
          }
        }
      }
      variants(first: 99) {
        edges {
          cursor
          node {
            ...ProductVariantFragment
          }
        }
      }
      options {
        id
        name
        values
      }
      priceRange {
        minVariantPrice {
          ...MoneyV2Fragment
        }
        maxVariantPrice {
          ...MoneyV2Fragment
        }
      }
      seo {
        description
        title
      }
    }
  }
  ${moneyV2Fragment}
  ${productVariantFragment}
  ${imageFragment}
`

import * as React from 'react'
import styled from '@xstyled/styled-components'

import { FormField, FormFieldProps } from './FormField'

/**
 * Styles
 */

const InputElement = styled.input`
  padding: 1 2 0;
  background-color: white;
  font-size: 3;
  border: 1px solid;
  width: 100%;
  font-weight: 4;
`
/**
 * Main component
 */
interface InputProps extends FormFieldProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  type?: React.HTMLInputTypeAttribute
}

export const Input: React.FC<InputProps> = ({
  label,
  value,
  type,
  id,
  name,
  onChange,
  required,
  validation,
}) => {
  return (
    <FormField label={label} id={id} value={value} validation={validation}>
      {({ onFocus, onBlur }) => (
        <InputElement
          type={type}
          name={name}
          onChange={onChange}
          value={value}
          onFocus={onFocus}
          onBlur={onBlur}
          required={required}
        />
      )}
    </FormField>
  )
}

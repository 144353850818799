import React, { useState } from 'react'
import { Modal } from '@/components/Modal'
import { ContactMessageType } from '@/types'
import { Heading, Span } from '@/components/Text'
import { useFormState } from '@/hooks/useFormState'
import { Select } from '@/components/Inputs/Select'
import { TextArea } from '@/components/Inputs/TextArea'
import { Button } from '@/components/Button'
import { Input } from '@/components/Inputs'
import { ButtonWrapper, InputWrapper, FormWrapper } from './styles'
import { ContactInquiryArgs } from '@/lib/postmark/emails/contactInquiry'
import { getContactMessageLabel } from '@/utils/contact'
import { x } from '@xstyled/styled-components'
import { Sentry } from '@/lib/sentry'

const contactOptions = Object.values(ContactMessageType).map((messageType) => ({
  value: messageType,
  label: getContactMessageLabel(messageType),
}))

/**
 * Validation
 */

const validateEmail = (email: string) => {
  if (email.length === 0) return 'Required'
  if (/^[\w\-\.]+@([\w-]+\.)+[\w-]{2,}$/.test(email) === false) {
    return 'Must be a valid email address'
  }
}

const validateName = (name: string) => {
  if (name.length === 0) return 'Required'
}
const validateBody = (body: string) => {
  if (body.length === 0) return 'Required'
  if (body.length > 800) {
    return `Must be under 800 characters. Current count: ${body.length}`
  }
  return undefined
}

export const ContactModal: React.FC = () => {
  const [messageBodyValue, setMessageBody] = useState('')
  const [nameValue, setNameValue] = useState('')
  const [emailValue, setEmailValue] = useState('')
  const [messageTypeValue, setMessageTypeValue] = useState<ContactMessageType>(
    Object.values(ContactMessageType)[0],
  )

  const { formState, errorMessage, setPending, handleSuccess, handleError } =
    useFormState()

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    // OK to assume that this will only be a ContactMessageType
    // @ts-ignore
    setMessageTypeValue(e.target.value)
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEmailValue(e.target.value)
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setNameValue(e.target.value)

  const handleBodyChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessageBody(e.target.value)
  }

  const allFieldsAreValid = Boolean(
    validateBody(messageBodyValue) === undefined &&
      validateEmail(emailValue) === undefined &&
      validateName(nameValue) === undefined,
  )

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setPending()
    const body: ContactInquiryArgs = {
      name: nameValue,
      email: emailValue,
      message: messageBodyValue,
      messageType: messageTypeValue,
    }

    try {
      const result = await fetch('/api/sendContactInquiry', {
        method: 'POST',
        body: JSON.stringify(body),
      }).then((r) => r.json() as Promise<{ success: boolean }>)
      if (result.success) {
        handleSuccess()
      } else {
        handleError('Sorry, there was an error submitting your message.')
      }
    } catch (e) {
      Sentry.captureException(e, 'postmark_error_client', body)
      handleError('Sorry, there was an error submitting your message.')
    }
  }

  return (
    <Modal backgroundColor="lightGray">
      <Heading
        level="h2"
        fontSize={4}
        fontWeight={1}
        textTransform="uppercase"
        textAlign="center"
      >
        Contact Us
      </Heading>
      <FormWrapper>
        {formState !== 'success' ? (
          <form onSubmit={handleSubmit}>
            <InputWrapper>
              <div className="input-container">
                <Input
                  label="Your Name"
                  id="name"
                  name="name"
                  value={nameValue}
                  onChange={handleNameChange}
                  validation={validateName}
                  required
                />
              </div>
              <div className="input-container">
                <Input
                  label="Email"
                  id="email"
                  name="email"
                  value={emailValue}
                  onChange={handleEmailChange}
                  validation={validateEmail}
                  required
                />
              </div>
              <div className="input-container">
                <Select
                  label="Inquiry Type"
                  id="message_type"
                  name="message_type"
                  value={messageTypeValue}
                  onChange={handleSelectChange}
                  required
                  options={contactOptions}
                />
              </div>
            </InputWrapper>
            <div className="input-container text">
              <TextArea
                onChange={handleBodyChange}
                value={messageBodyValue}
                validation={validateBody}
                id="message_body"
                name="message_body"
                required
              />
            </div>
            <ButtonWrapper>
              <Button type="submit" disabled={!allFieldsAreValid}>
                Submit
              </Button>
              {errorMessage ? <Span color="red">{errorMessage}</Span> : null}
            </ButtonWrapper>
          </form>
        ) : (
          <x.div textAlign="center">
            <x.p fontSize={3}>Thank you!</x.p>
          </x.div>
        )}
      </FormWrapper>
    </Modal>
  )
}

import { createGlobalStyle, css } from '@xstyled/styled-components'

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html,
  body,
  #__next {
    scrollbar-width: none;
    height: 100%;
  }
  body {
    width: 100%;
  }

  html {
    margin: 0;
  }

  body {
    font-family: serif;
    overflow-x: hidden;
    color: var(--black);
    background-color: var(--one);
    margin: 0;
  }

  h1 {
    font-size: 1;
  }
  h2 {
    font-size: 2;
  }
  h3 {
    font-size: 3;
  }
  h4 {
    font-size: 4;
  }
  p,
  h5 {
    font-size: 5;
  }
  h6 {
    font-size: 6;
  }

    *:focus {
      outline-offset: 2px;
      outline-style: none;
    }


  button, input, select, option, textarea {
    font: serif;
    background: white;
    font-weight: 300;
    border: none;
    outline: none;
    line-height: normal;
    padding: 0;
    border-radius: 0;
  }

  img {
    max-width: 100%;
  }

  button {
    cursor: pointer;
    border: none;
    font-family: inherit;
    background-color: transparent;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  p a {
    text-decoration: underline;
  }

  :root {
    --black: #000;
    --white: #fff;
    --one: #f7a1d0;
    --two: #60caf4;
    --three: #cbc57c;
    --four: #ceff00;
    --five: #0b5e1f;
    --six: #b78909;
    --seven: #f9b282;
    --sans: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    --serif: 'Baskerville', Georgia, serif;
    --h1: 1;
    --h2: 2;
    --h3: 3;
    --h4: 4;
    --h5: 5;
  }
`

import { config } from '@/config'
import { DocumentNode } from 'graphql'
import { request as gqlRequest } from 'graphql-request'

const storefrontUrl = `https://${config.shopify.shopName}.myshopify.com/api/2023-01/graphql.json`

type ClientConfig = {
  headers?: Record<string, any>
}

const createGraphQLClient = (
  endpoint: string,
  config: ClientConfig = {},
): GraphQLClient => {
  function query<ReturnType = any>(
    query: DocumentNode | string,
    variables?: Record<string, any>,
  ): Promise<ReturnType> {
    return gqlRequest({
      url: endpoint,
      document: query,
      variables,
      requestHeaders: config.headers,
    })
  }
  return { query }
}

export type GraphQLClient = {
  query: <ReturnType = any>(
    query: DocumentNode | string,
    variables?: Record<string, any>,
  ) => Promise<ReturnType>
}

export const shopifyClient = createGraphQLClient(storefrontUrl, {
  headers: {
    'X-Shopify-Storefront-Access-Token': config.shopify.accessToken,
  },
})

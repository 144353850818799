import * as React from 'react'

import { Button } from '@/components/Button'
import { Span } from '@/components/Text'

interface NoLineItemsProps {
  closeCartModal: () => void
}

export const NoLineItems: React.FC<NoLineItemsProps> = ({ closeCartModal }) => {
  return (
    <div className="no-line-items">
      <Span>Oops! Your bag is empty.</Span>
      <Button onClick={closeCartModal}>Continue shopping</Button>
    </div>
  )
}

import React from 'react'

import { useModal } from '@/providers/ModalProvider'
import { CartModal } from './Cart'
import { ContactModal } from './Contact'

export const Modals: React.FC = () => {
  const { currentModal } = useModal()
  if (currentModal === null) return null
  switch (currentModal) {
    case 'cart':
      return <CartModal />
    case 'contact':
      return <ContactModal />
    default:
      // @ts-expect-error
      console.warn(`"${currentModal.toString()}" is not a valid modal`)
      return null
  }
}

import * as React from 'react'
import { Required } from 'utility-types'
import styled from '@xstyled/styled-components'

const BaseText = styled.spanBox``

// Add this type to handle polymorphic 'as' prop
type PolymorphicBaseText = typeof BaseText & {
  as?: React.ElementType
}

// Update the type reference
type BaseTextProps = React.ComponentProps<PolymorphicBaseText>

const BaseLabel = styled.label`
  font-family: var(--serif);
  font-size: 3;
  display: block;
  text-transform: uppercase;
`

type BaseLabelProps = React.ComponentProps<typeof BaseLabel>

type TextProps = BaseTextProps & { as: React.ElementType }

export const Span: React.FC<BaseTextProps> = (props) => {
  return <BaseText {...props} />
}

export const Text: React.FC<TextProps> = ({ as, ...textProps }) => (
  <BaseText as={as} {...textProps} />
)

type HeadingProps = BaseTextProps & {
  level: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
}

export const Heading: React.FC<HeadingProps> = ({ level, ...textProps }) => (
  // @ts-ignore
  <BaseText as={level} {...textProps} />
)

/**
 * Some simple wrappers with sensible defaults
 */
export const P: React.FC<BaseTextProps> = (props) => (
  // @ts-ignore
  <BaseText fontFamily="serif" fontSize={5} {...props} as="p" />
)

export const Label: React.FC<BaseLabelProps> = (props) => (
  <BaseLabel {...props} />
)

import * as React from 'react'
import { ShopifyStorefrontCheckoutLineItem } from '@/types'
import {
  CheckoutLineItemDetails,
  CheckoutLineItemImageWrapper,
  CheckoutLineItemWrapper,
  LineItemSummaryWrapper,
} from './styles'
import { ShopifyImage } from '@/components/ShopifyImage'
import { Text } from '@/components/Text'
import { formatPrice } from '@/utils'
import { useShopify } from '@/providers/ShopifyProvider'

/**
 * Line Item summary
 */
interface CheckoutLineItemProps {
  lineItem: ShopifyStorefrontCheckoutLineItem
}

export const CheckoutLineItem: React.FC<CheckoutLineItemProps> = ({
  lineItem,
}) => {
  const { removeLineItem } = useShopify()
  const { title, variant, quantity } = lineItem
  const image = variant?.image
  const price = variant?.priceV2
  const options = variant?.selectedOptions
  return (
    <CheckoutLineItemWrapper>
      {image ? (
        <CheckoutLineItemImageWrapper>
          <ShopifyImage image={image} />
        </CheckoutLineItemImageWrapper>
      ) : (
        /* An empty div to keep the details in the appropriate grid column */
        <div />
      )}
      <CheckoutLineItemDetails>
        <Text
          as="p"
          textTransform="uppercase"
          fontWeight={5}
          fontSize={3}
          fontFamily="sans"
          className="title"
        >
          {title}
          <br />
          {price ? formatPrice(price) : null}
        </Text>
        {options
          ? options.map((option) => (
              <Text key={option.name} as="p" fontSize={5} fontFamily="serif">
                {option.name}: {option.value}
              </Text>
            ))
          : null}
        <Text as="p" fontSize={5} fontFamily="serif">
          Quantity: {quantity}
        </Text>
        <button onClick={() => removeLineItem(lineItem.id)}>Remove</button>
      </CheckoutLineItemDetails>
    </CheckoutLineItemWrapper>
  )
}

/**
 * Main wrapper
 */

interface LineItemSummaryProps {
  lineItems: ShopifyStorefrontCheckoutLineItem[]
}

export const LineItemSummary: React.FC<LineItemSummaryProps> = ({
  lineItems,
}) => {
  return (
    <LineItemSummaryWrapper>
      {lineItems.map((lineItem) => (
        <CheckoutLineItem lineItem={lineItem} key={lineItem.id} />
      ))}
    </LineItemSummaryWrapper>
  )
}

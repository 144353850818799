import * as SentryClient from '@sentry/nextjs'

type SentryEvent =
  | 'postmark_error_api'
  | 'postmark_error_client'
  | 'mailchimp_error_api'
  | 'mailchimp_error_client'
  | 'page_fetch_error'
  | 'paths_fetch_error'
  | 'unknown'

/**
 * A wrapper for the base Sentry client that enforces
 * the usage of tags, and makes passing additional information
 * a little easier.
 *
 * Example usage:
 *
 * Sentry.captureException(someError, 'page_fetch_error', helpfulContext)
 */
export const Sentry = {
  captureException: (
    e: any,
    event: SentryEvent,
    extra?: Record<string, any>,
  ) => {
    console.error(e)
    SentryClient.captureException(e, { tags: { event: event }, extra })
  },
}

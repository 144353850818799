import styled, { css } from '@xstyled/styled-components'

interface WithFixed {
  fixed?: boolean
}

interface WithFixedBottomHeight {
  fixed?: boolean
  bottomHeight?: number
}

export const Nav = styled.nav<WithFixedBottomHeight>`
  ${({ fixed, bottomHeight }) => css`
    padding-bottom: ${fixed == true ? `${bottomHeight}px` : '0'};
  `}
`

export const NavTop = styled.div`
  background-color: var(--one);
  padding: 0;
  h1.motion-logo {
    margin: 2 6;
    padding: 4 0;
    line-height: 0;
  }
  @media screen and (max-width: 1024px) {
    h1.motion-logo {
      margin: 2 6;
      padding: 2 0;
    }
  }
  @media screen and (max-width: 640px) {
    h1.motion-logo {
      z-index: 35;
    }
  }
`

export const NavBottom = styled.div<WithFixed>`
  ${({ fixed }) => css`
    background-color: turtleGreen;
    padding: 2 8;
    display: flex;
    justify-content: space-between;
    position: ${fixed == true ? 'fixed' : 'static'};
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 33;
    @media screen and (max-width: 1024px) {
      padding: 2 4;
    }
  `}
`

export const ToolsWrapper = styled.div`
  display: flex;
  align-items: center;
  a {
    line-height: 0;
  }
  @media screen and (max-width: 1024px) {
    padding: 3 0;
  }
  @media screen and (max-width: 640px) {
    padding: 2 0;
  }
`
export const CartIconWrapper = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: -60px;
  position: relative;
  justify-content: center;
  width: 0px;
  img {
    margin-right: 2;
    width: 65px;
    height: 60px;
    max-width: 65px;
    position: absolute;
    top: 50%;
    left: 50%;
    bottom: 0;
    right: 0;
    transform: translate(-50%, -50%);
  }
  span {
    text-transform: uppercase;
    text-decoration: none;
    padding: 0 2;
    font-size: 5;
    line-height: 1;
    display: block;
    position: relative;
    top: 6px;
    letter-spacing: -1px;
    color: black;
  }
  @media screen and (max-width: 1024px) {
    margin-left: -40px;
    margin-right: 0;
    width: 0px;
    height: 20px;
    img {
      width: 50px;
      height: 40px;
      max-width: 50px;
    }
    span {
      font-size: 18px;
    }
  }
  @media screen and (max-width: 640px) {
    margin-left: 2;
    margin-right: 2;
    width: 40px;
    height: 20px;
    img {
      width: 45px;
      height: 35px;
      max-width: 45px;
    }
  }
`
export const NavLinksWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 4;
  flex: 1;
  justify-content: space-between;
  max-width: 800px;
  margin: 0 auto;
  a,
  button {
    text-transform: uppercase;
    text-decoration: none;
    font-size: 5;
    color: black;
  }
  a:hover,
  a.active,
  button:hover {
    text-decoration: underline;
  }
  img {
    margin: 0;
  }
  .menu-toggle {
    display: none;
    z-index: 35;
  }
  @media screen and (max-width: 1024px) {
    max-width: 720px;
    a,
    button {
      font-size: 5;
      padding: 2;
    }
    .nav-image {
      height: 40px;
    }
  }
  @media screen and (max-width: 640px) {
    justify-content: flex-start;
    a,
    button,
    img {
      display: none;
    }
    .menu-toggle {
      display: block;
    }
  }
`

export const NavButton = styled.button`
  border: none;
  padding: 0 2;
  margin: none;
  color: black;
`
interface WithOpen {
  open?: boolean
}

export const MenuWrapperMobile = styled.div<WithOpen>`
  ${({ open }) => css`
    display: none;
    background-color: turtleGreen;
    padding: 180px 10 10 2;
    height: 100vh;
    justify-content: space-between;
    flex-direction: column;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 33;
    bottom: 0px;
    a,
    button {
      text-transform: uppercase;
      text-decoration: none;
      padding: 0 6;
      font-size: 4;
      color: black;
    }
    a:hover,
    a.active,
    button:hover {
      text-decoration: underline;
    }
    .menu-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    @media screen and (max-width: 640px) {
      display: ${open ? 'flex' : 'none'};
    }
  `}
`

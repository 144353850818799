/**
 * This value will be "staging" on all vercel preview deployments
 * and "production" on production builds.
 * There is no need to add this value to your local .env
 * unless you want to test sending exceptions to Sentry.
 */
const VERCEL_ENV = process.env.VERCEL_ENV

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

export const sharedSettings = {
  dsn:
    SENTRY_DSN ||
    'https://b686475a5a3f411db5aef0d42dd3385b@o1131872.ingest.sentry.io/4504641802993664',
  tracesSampleRate: 1.0,
  environment: VERCEL_ENV,
  disabled: Boolean(VERCEL_ENV === undefined),
}

import { gql } from 'graphql-request'
import { imageFragment, moneyV2Fragment } from '@/lib/client/graphql/shared'
import { productVariantFragment } from '@/lib/client/graphql/product'

export const discountApplicationFragment = gql`
  fragment DiscountApplicationFragment on DiscountApplication {
    __typename
    allocationMethod
    targetSelection
    targetType
    ... on DiscountCodeApplication {
      __typename
      code
      applicable
    }
    value {
      ... on PricingPercentageValue {
        __typename
        percentage
      }
      ... on MoneyV2 {
        ...MoneyV2Fragment
      }
    }
  }
  ${moneyV2Fragment}
`

export const lineItemFragment = gql`
  fragment LineItemFragment on CheckoutLineItem {
    __typename
    id
    quantity
    title
    discountAllocations {
      __typename
      allocatedAmount {
        ...MoneyV2Fragment
      }
      discountApplication {
        ...DiscountApplicationFragment
      }
    }
    variant {
      ...ProductVariantFragment
      # TODO: add product { ... } fields we need for checkout
    }
  }
  ${discountApplicationFragment}
  ${productVariantFragment}
`

export const checkoutFragment = gql`
  fragment CheckoutFragment on Checkout {
    __typename
    id
    paymentDueV2 {
      ...MoneyV2Fragment
    }
    subtotalPriceV2 {
      ...MoneyV2Fragment
    }
    totalTaxV2 {
      ...MoneyV2Fragment
    }
    webUrl
    completedAt
    shippingLine {
      __typename
      handle
      price {
        ...MoneyV2Fragment
      }
      title
    }
    email
    discountApplications(first: 100) {
      __typename
      pageInfo {
        __typename
        hasNextPage
        hasPreviousPage
      }
      edges {
        __typename
        cursor
        node {
          ...DiscountApplicationFragment
        }
      }
    }
    lineItems(first: 100) {
      __typename
      pageInfo {
        __typename
        hasNextPage
        hasPreviousPage
      }
      edges {
        __typename
        cursor
        node {
          ...LineItemFragment
        }
      }
    }
  }

  ${moneyV2Fragment}
  ${discountApplicationFragment}
  ${lineItemFragment}
  ${imageFragment}
`

import styled, { css } from '@xstyled/styled-components'

export const FormWrapper = styled.div`
  ${({ theme }) => css`
    label {
      font-size: 6;
      padding-bottom: 1;
    }
    .input-container {
      padding: 5 1 1 1;
      position: relative;
    }
    .input-container.text {
      padding: 3 1 1 1;
    }
    input,
    select {
      font-size: 5;
      line-height: 1;
      padding: 3 2;
    }
    textarea {
      padding: 3;
      font-size: 5;
      font-family: body;
      min-height: 160px;
    }
    span {
      font-size: 6;
      font-family: body;
      padding-top: 2;
      display: inline-block;
      position: absolute;
      left: 4px;
      bottom: -1.2em;
    }
    @media screen and (max-width: 1024px) {
      label {
        font-size: 6;
      }
      input,
      select {
        font-size: 5;
      }
      textarea {
        padding: 3;
        font-size: 5;
        min-height: 160px;
      }
    }
  `}
`

export const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-top: 4;
    ${theme.mediaQueries.tablet} {
      width: 50%;
      margin-left: auto;
      margin-top: 5;

      > button {
        margin-bottom: 2;
      }
    }
  `}
`

export const InputWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-bottom: 1;
    ${theme.mediaQueries.tablet} {
      width: 50%;
      margin-right: auto;
    }
  `}
`

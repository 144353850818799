export * from './generated-shopify'
export * from './product'

/**
 * Contact
 */

/* NOTE: the order of this enum determines the order of the options
 * in the select menu modal. */
export enum ContactMessageType {
  SHOP = 'SHOP',
  SHIPPING_RETURNS = 'SHIPPING_RETURNS',
  // GENERAL = 'GENERAL',
  // CAREERS = 'CAREERS',
}

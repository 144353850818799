import { useFormState } from '@/hooks/useFormState'
import { x } from '@xstyled/styled-components'
import React, { FC, HTMLInputTypeAttribute, useState } from 'react'
import { Span } from '../Text'
import { Input, InputButton, InputWithButtonWrapper } from './styles'

type SubmitResult = { success: boolean; message: string }

interface InputWithButtonProps {
  name: string
  type?: HTMLInputTypeAttribute
  placeholder?: string
  initialValue?: string
  onSubmit: (currentValue: string) => Promise<SubmitResult>
}

export const InputWithButton: FC<InputWithButtonProps> = ({
  placeholder,
  initialValue = '',
  type,
  name,
  onSubmit,
}) => {
  const { formState, errorMessage, setPending, handleSuccess, handleError } =
    useFormState()
  const [message, setMessage] = useState<SubmitResult | void>(undefined)
  const [currentValue, setCurrentValue] = useState(initialValue)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(e.target.value)
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setPending()
    try {
      const result = await onSubmit(currentValue)
      setMessage(result)
      if (result.success) {
        handleSuccess()
      } else {
        handleError('Sorry, there was an error submitting your request.')
      }
    } catch (e) {
      handleError('Sorry, there was an error submitting your request.')
    }
  }

  return (
    <>
      <InputWithButtonWrapper onSubmit={handleSubmit}>
        <Input
          name={name}
          disabled={formState !== 'idle'}
          type={type}
          placeholder={placeholder}
          value={currentValue}
          onChange={handleChange}
        />
        {currentValue.length > 0 ? (
          <InputButton
            type="submit"
            disabled={formState !== 'idle'}
            aria-label="Submit"
          >
            {formState === 'success' ? '✔' : '>'}
          </InputButton>
        ) : null}
      </InputWithButtonWrapper>
      <x.div mt={3}>
        <Span fontSize={5}>{message?.message || <>&nbsp;</>}</Span>
      </x.div>
      {errorMessage ? (
        <x.div mt={1}>
          <Span color="red" fontSize={5}>
            {message?.message || <>&nbsp;</>}
          </Span>
        </x.div>
      ) : null}
    </>
  )
}
